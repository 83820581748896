import axios from 'axios';
import { encode } from 'base-64';
import { HttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

class BazerService {
    constructor() {
        this.apiUrl = "https://bazerbot.com/api";
        this.graphqlUrl = 'https://bazerbot.com/api/graphql';
        // this.apiUrl = "http://localhost:3001/api";
        // this.graphqlUrl = 'http://localhost:3001/api/graphql';
    }

    async Login(seedPhrase) {
        try {
            const basicString = encode(`mnemonic:${seedPhrase}:3ZM9nHv7j3`);
            let config = {
                method: 'get',
                url: `${this.apiUrl}/getLogin`,
                headers: {
                    'Authorization': `Basic ${basicString}`
                }
            };

            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log("BazerService.Login: ", error);
            return { status: "error" };
        }
    }

    async createApolloClient(seedPhrase, path) {
        const login = await this.Login(seedPhrase);

        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: login.token ? `Bearer ${login.token}` : '',
                },
            };
        });

        const httpLink = new HttpLink({
            uri: `${this.graphqlUrl}${path}`
        });

        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
        });
    }

    async UserInfo(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/user");

            const response = await client.query({
                query: gql`
                query InfoUser {
                    infoUser {
                        status
                        data
                        error {
                            message
                        }
                    }
                }`
            });
            return this.apiResponse(response.data.infoUser);
        } catch (error) {
            console.log("BazerService.UserInfo: ", error);
            return { status: "error", data: [] };
        }
    }

    async BalanceList(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/balance");
            const response = await client.query({
                query: gql`
                    query BalanceList {
                        balanceList {
                            status
                            data {
                                name
                                fullName
                                minAmount
                                enabled
                                image
                                price
                                balance
                                address
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.balanceList);
        } catch (error) {
            console.log("BazerService.BalanceList: ", error);
            return { status: "error", data: [] };
        }
    }

    async BalanceNft(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/balance");
            const response = await client.query({
                query: gql`
                    query BalanceList {
                        balanceNft {
                            status
                            data {
                                nftId
                                quantity
                                nftCollection
                                reserve
                                delegationAmount
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.balanceNft);
        } catch (error) {
            console.log("BazerService.BalanceNft: ", error);
            return {
                status: "error",
                data: {
                    nfts: []
                }
            };
        }
    }

    async CreateNft(seedPhrase, data) {
        try {
            const login = await this.Login(seedPhrase);
            let config = {
                method: 'post',
                url: `${this.apiUrl}/wallet/createNftDecimalchain`,
                data: {
                    "coverUrl": data.linkImage,
                    "assetUrl": data.linkImage,

                    "headline": data.headline,
                    "description": data.description,

                    "mnemonics": null,
                    "recipient": null,

                    "transaction": {
                        "network": "mainnet"
                    },

                    "options": {
                        "simulate": false
                    },

                    "payload": {
                        "denom": data.denom,
                        "quantity": data.quantity,
                        "reserveDenom": "del",
                        "reserveAmount": data.reserveAmount,
                        "allowMint": true
                    }
                },
                headers: {
                    'Authorization': `Bearer ${login.token}`
                }
            };

            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log("BazerService.CreateNft: ", error);
            return { status: "error" };
        }
    }

    async ListSellNft(seedPhrase) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/marketplace");
            const response = await client.query({
                query: gql`
                    query ListSellNft {
                        listSellNft {
                            status
                            data {
                                listNft {
                                    userId
                                    type
                                    productId
                                    name
                                    price
                                    imageUrl
                                    description
                                    requisites
                                    currency
                                    pledge
                                    outpost
                                    status
                                }
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.listSellNft);
        } catch (error) {
            console.log("BazerService.listSellNft: ", error.message);
            return {
                status: "error",
                data: {
                    nfts: []
                }
            };
        }
    }

    async SellNft(seedPhrase, price, currency, requisites, nftId, type) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/marketplace");
            const response = await client.query({
                query: gql`
                    query SellNft {
                        sellNft(price: ${price}, currency: "${currency}", requisites: "${requisites}", nftId: "${nftId}", type: "${type}") {
                            status
                            data {
                                userId
                                type
                                productId
                                name
                                price
                                imageUrl
                                description
                                requisites
                                currency
                                pledge
                                outpost
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.sellNft);
        } catch (error) {
            console.log("BazerService.SellNft: ", error.message);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }

    async BuyNft(seedPhrase, productId) {
        try {
            const client = await this.createApolloClient(seedPhrase, "/marketplace");
            const response = await client.query({
                query: gql`
                    query BuyNft {
                        buyNft(productId: "${productId}") {
                            status
                            data {
                                buyerUrl
                                sellerUrl
                            }
                            error {
                                message
                            }
                        }
                    }`
            });
            return this.apiResponse(response.data.buyNft);
        } catch (error) {
            console.log("BazerService.BuyNft: ", error.message);
            return {
                status: "error",
                error: { message: "Ошибка" }
            };
        }
    }

    async infoNft(nftId) {
        try {
            let config = {
                method: 'get',
                url: `https://mainnet-gate.decimalchain.com/api/nfts/${nftId}`,
            };

            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log("BazerService.BalanceNft: ", error);
            return {
                status: "error", data: {
                    nfts: []
                }
            };
        }
    }

    apiResponse(response) {
        return {
            status: response.status,
            data: response.data,
            error: response.error
        };
    }
}

export default new BazerService();