import React, { useState } from 'react';
import Toast from 'react-native-toast-message';
import { decrypt } from '../helpers/crypto.js';
import AuthService from '../api/auth/AuthService.js';
import StorageService from '../service/StorageService.js';
import { StyleSheet, Text, View, Image, TouchableOpacity, TextInput } from 'react-native';

export const AuthScreen = ({ navigation }) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            const response = await AuthService.Login(login.trim(), password.trim());

            if (response.status !== "success") {
                Toast.show({
                    type: 'error',
                    text1: response.error,
                });
                return;
            }

            const { accessToken, refreshToken } = response.data;
            await saveTokens(accessToken, refreshToken); // Сохраняем токены
            // Проверка валидности accessToken
            const verify = await AuthService.verify(accessToken);

            // Если accessToken валиден
            if (verify.status === "success") {
                const { stakingKey } = verify.data;
                const decryptedStakingKey = await decrypt(stakingKey);
                navigation.navigate("Main", { seedPhrase: decryptedStakingKey });
            } else {
                Toast.show({
                    type: 'error',
                    text1: verify.error,
                });
                return;
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: "An error occurred during login. Please try again.",
            });
        }
    };

    // Функция для сохранения токенов
    const saveTokens = async (accessToken, refreshToken) => {
        await StorageService.storeData("accessToken", accessToken);
        await StorageService.storeData("refreshToken", refreshToken);
    };

    return (
        <View style={styles.container}>
            <Image source={require('../img/2.png')} style={styles.logo} />
            <Text style={styles.title}>Login to Your Account</Text>
            <Text style={styles.subtitle}>
                Access your account securely. Manage your digital assets with ease — no hidden fees, no surprises.
            </Text>
            <TextInput
                style={styles.input}
                onChangeText={setLogin}
                value={login}
                placeholder="Enter your login"
                autoCapitalize="none"
            />
            <TextInput
                style={styles.input}
                onChangeText={setPassword}
                value={password}
                placeholder="Enter your password"
                secureTextEntry
            />
            <TouchableOpacity style={styles.button} onPress={handleLogin}>
                <Text style={styles.buttonText}>Login</Text>
            </TouchableOpacity>
            <Toast style={{ zIndex: 9999 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        backgroundColor: '#fff',
    },
    logo: {
        width: 150,
        height: 150,
        marginBottom: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: 16,
        color: '#666',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        height: 50,
        marginVertical: 10,
        borderWidth: 1,
        padding: 10,
        width: '100%',
        borderRadius: 5,
        borderColor: '#ddd',
        fontSize: 16,
    },
    button: {
        backgroundColor: '#007bff',
        padding: 15,
        borderRadius: 5,
        width: '100%',
        marginBottom: 10,
    },
    buttonText: {
        fontSize: 18,
        color: '#fff',
        textAlign: 'center',
    },
});