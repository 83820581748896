import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, FlatList, Button, Modal } from 'react-native';
import BazerService from '../api/bazer/BazerService.js';
import ReplenishModal from '../component/ReplenishModal.js';
import SendModal from '../component/SendModal.js';
import Loader from '../component/Loader.js';

export const CabinetScreen = ({ seedPhrase }) => {
    const [loading, setLoading] = useState(true);
    const [coinsData, setCoinsData] = useState([]);
    const [showSendModal, setShowSendModal] = useState(false);
    const [showReplenishModal, setShowReplenishModal] = useState(false);
    const [replenishInfo, setReplenishInfo] = useState({ coin: '', minAmount: 0, address: '' });

    useEffect(() => {
        getBalanceData();
    }, []);

    const getBalanceData = async () => {
        try {
            const response = await BazerService.BalanceList(seedPhrase);
            setCoinsData(response.data);
        } catch (error) {
            console.error('Error coins data:', error);
        } finally {
            setLoading(false);
        }
    };

    const refreshData = async () => {
        setLoading(true);
        await getBalanceData();
    };

    const handleReplenish = async (coinName) => {
        const findCoin = coinsData.find(e => e.name === coinName);
        setReplenishInfo({
            coin: coinName,
            minAmount: findCoin.minAmount,
            address: findCoin.address,
        });
        setShowReplenishModal(true);
    };

    const handleCloseReplenishModal = () => {
        setShowReplenishModal(false);
    };

    const handleSend = async () => {
        setShowSendModal(true);
    };

    const handleCloseSendModal = () => {
        setShowSendModal(false);
    };

    const renderCoinItem = ({ item }) => {
        return (
            <View style={styles.coinItem}>
                <View style={styles.coinDetails}>
                    <Text style={styles.coinName}>{item.name}</Text>
                    <Text style={styles.coinBalance}>Balance: {item.balance}</Text>
                </View>
                <View style={styles.coinButton}>
                    <Button style={styles.replenishButton} title="Replenish" onPress={() => handleReplenish(item.name)} />
                    <Button style={styles.sendButton} title="Send" onPress={handleSend} />
                </View>
            </View>
        );
    };

    if (loading) return (<Loader />);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>Balance:</Text>
                <Button title="Refresh" onPress={refreshData} />
            </View>
            <FlatList
                data={coinsData}
                renderItem={renderCoinItem}
                keyExtractor={item => item.fullName}
            />

            <ReplenishModal visible={showReplenishModal} onClose={handleCloseReplenishModal} replenishInfo={replenishInfo} />
            <SendModal visible={showSendModal} onClose={handleCloseSendModal} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    coinItem: {
        marginBottom: 15,
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        borderRadius: 5,
    },
    coinDetails: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    coinButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    sendButton: {
        flex: 1,
        marginLeft: 5,
    },
    replenishButton: {
        flex: 1,
        marginRight: 5
    },
});
