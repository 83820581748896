import React from 'react';
import QRCode from 'react-native-qrcode-svg';
import Toast from 'react-native-toast-message';
import { Modal, View, Text, Button, StyleSheet, Clipboard } from 'react-native';

const ReplenishModal = ({ visible, onClose, replenishInfo }) => {
    const showToastSuccess = (text) => Toast.show({
        type: 'success',
        text1: text,
    });

    const copyToClipboard = () => {
        showToastSuccess("Copied to clipboard");
        Clipboard.setString(replenishInfo.address);
    };

    return (
        <Modal
            visible={visible}
            animationType="slide"
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <Toast />
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>Replenish</Text>
                    <View style={styles.qrCodeContainer}>
                        <QRCode value={replenishInfo.address} size={200} />
                    </View>
                    <View style={styles.infoContainer}>
                        <View style={styles.addressContainer}>
                            <Text style={styles.addressText}>{replenishInfo.address}</Text>
                            <Button title="Copy address" onPress={copyToClipboard} style={styles.copyButton} />
                        </View>
                    </View>
                    <View style={styles.creditInfoContainer}>
                        <Text style={styles.replenishInfoText}>Amounts less than {replenishInfo.minAmount} {replenishInfo.coin} will not be credited.</Text>
                    </View>
                    <View style={styles.closeButtonContainer}>
                        <Button title="Close" onPress={onClose} />
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Прозрачный цвет фона подложки
    },
    modalContent: {
        backgroundColor: '#fff', // Изменяем цвет фона контента модального окна
        padding: 20,
        borderRadius: 10,
        width: '80%',
        maxHeight: '80%', // Ограничиваем максимальную высоту модального окна
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    infoContainer: {
        marginBottom: 20,
        backgroundColor: 'lightgray', // Задаем серый цвет фона подложки
        borderRadius: 5, // Добавляем скругление углов подложки
        padding: 10, // Добавляем отступы внутри подложки
        flexDirection: 'row', // Располагаем элементы в строку
        justifyContent: 'space-between', // Выравниваем элементы по краям контейнера
        alignItems: 'center', // Выравниваем элементы по вертикали
    },
    addressContainer: {
        flex: 1, // Разделение пространства между текстом адреса и кнопкой
    },
    addressText: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: 5, // Добавляем отступ снизу для разделения адреса и кнопки
        flexWrap: 'wrap', // Разрешаем перенос текста на новую строку
        maxWidth: '95%', // Ограничиваем максимальную ширину текста
    },
    copyButton: {
        fontSize: 16,
        color: '#fff', // Изменяем цвет текста кнопки на белый
        textDecorationLine: 'underline',
        backgroundColor: 'blue', // Изменяем цвет фона кнопки на синий
        paddingHorizontal: 10, // Добавляем отступы слева и справа для кнопки
        paddingVertical: 5, // Добавляем отступы сверху и снизу для кнопки
    },
    creditInfoContainer: {
        backgroundColor: 'gray',
        padding: 10,
        borderRadius: 5,
        justifyContent: 'center',
    },
    replenishInfoText: {
        fontSize: 16,
        textAlign: 'center',
    },
    closeButtonContainer: {
        marginTop: 10,
    },
    qrCodeContainer: {
        backgroundColor: 'gray',
        alignItems: 'center',
        marginVertical: 20, // Добавляем вертикальные отступы вокруг QR-кода
        padding: 20, // Добавляем отступы вокруг QR-кода
        borderRadius: 10, // Добавляем скругление углов подложки вокруг QR-кода
        alignSelf: 'center', // Выравниваем по центру горизонтально
    },
});

export default ReplenishModal;
