import Loader from '../component/Loader';
import Toast from 'react-native-toast-message';
import React, { useState, useEffect } from 'react';
import BazerService from '../api/bazer/BazerService';
import { StyleSheet, View, Text, FlatList, Image, TouchableOpacity, Modal, Button, TextInput, Picker, Linking } from 'react-native';

const NftMarketplaceScreen = ({ seedPhrase }) => {
    const [nfts, setNfts] = useState([]);
    const [nftData, setNftData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedNft, setSelectedNft] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [sellModalVisible, setSellModalVisible] = useState(false);
    const [selectedSellNft, setSelectedSellNft] = useState("");
    const [sellPrice, setSellPrice] = useState("");
    const [currency, setCurrency] = useState('UAH');
    const [paymentAddress, setPaymentAddress] = useState("");


    // Функция для загрузки данных NFT через API
    const fetchNfts = async () => {
        try {
            const response = await BazerService.ListSellNft(seedPhrase);
            setNfts(response.data.listNft);
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };

    useEffect(() => {
        fetchNfts();
    }, []);

    // Функция для открытия модального окна с подробной информацией
    const handleNftPress = (item) => {
        setSelectedNft(item);
        setModalVisible(true);
    };

    // Функция для закрытия модального окна
    const closeModal = () => {
        setModalVisible(false);
    };

    // Функция для подтверждения покупки
    const handleBuyNft = async () => {
        const buyNft = await BazerService.BuyNft(seedPhrase, selectedNft.productId);
        if (buyNft.status === "OK") {
            Toast.show({
                type: 'success',
                text1: 'Успех! Перейдите в bazerbot для оформления сделки!',
            });
        } else {
            Toast.show({
                type: 'error',
                text1: buyNft.error.message,
            });
        }
        setModalVisible(false);
        fetchNfts();
    };

    // Функция для загрузки данных о NFT
    const loadNftData = async () => {
        try {
            setLoading(true);
            const nftData = await BazerService.BalanceNft(seedPhrase);
            setNftData(nftData.data); // Устанавливаем обновленные данные в state
        } catch (error) {
            setLoading(false);
            console.error("Error loading NFT data:", error);
        } finally {
            setLoading(false);
        }
    };

    const openLink = (link) => Linking.openURL(link.split("|")[1]);


    // Функция для открытия модального окна продажи
    const openSellModal = () => {
        loadNftData();
        setSellModalVisible(true);
    };

    // Функция для закрытия модального окна продажи
    const closeSellModal = () => {
        setSellModalVisible(false);
    };

    // Функция для обработки продажи NFT
    const handleSellNft = async () => {
        const sellNft = await BazerService.SellNft(seedPhrase, sellPrice, currency, paymentAddress, selectedSellNft.nftId, "nft");
        if (sellNft.status === "OK") {
            Toast.show({
                type: 'success',
                text1: 'Успех!',
            });
        } else {
            Toast.show({
                type: 'error',
                text1: sellNft.error.message,
            });
        }
        setSellModalVisible(false);
        fetchNfts();
    };

    const currencies = ['UAH', 'RUB', 'USDT', 'TRON'];

    const renderNFTItem = ({ item }) => (
        <TouchableOpacity style={styles.nftItem} onPress={() => handleNftPress(item)}>
            <Image source={{ uri: item.imageUrl }} style={styles.nftImage} />
            <View style={styles.nftInfo}>
                <Text style={styles.nftName}>{item.name}</Text>
                <Text style={styles.nftArtist}>{item.userId}</Text>
                <Text style={styles.nftPrice}>{item.price} {item.currency}</Text>
            </View>
        </TouchableOpacity>
    );

    return (
        <View style={styles.container}>
            <Text style={styles.header}>NFT Marketplace</Text>
            <FlatList
                data={nfts}
                renderItem={renderNFTItem}
                keyExtractor={(item) => item.productId}
                style={styles.nftList}
            />

            {/* Модальное окно с подробной информацией */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={closeModal}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>{selectedNft?.name}</Text>
                        <Image source={{ uri: selectedNft?.imageUrl }} style={styles.modalImage} />
                        <Text style={styles.modalInfo}>Artist: {selectedNft?.userId}</Text>
                        <Text style={styles.modalInfo}>Price: {selectedNft?.price.toFixed(2)} {selectedNft?.currency}</Text>
                        <Text style={styles.modalInfo}>Pledge: {selectedNft?.pledge.toFixed(2)} cashback</Text>
                        <Text style={styles.modalInfo}>Outpost: {selectedNft?.outpost.toFixed(2)} cashback</Text>
                        <Text style={styles.modalInfo}>Description: {selectedNft?.description.split("|")[0]}</Text>

                        <View style={styles.modalButtons}>
                            <Button title="Close" onPress={closeModal} />
                            <Button title="Open Passport" onPress={() => openLink(selectedNft.description)} />
                            <Button title="Buy NFT" onPress={handleBuyNft} />
                        </View>
                    </View>
                    <Toast style={{ zIndex: 9999 }} />
                </View>
            </Modal>

            {/* Модальное окно продажи NFT */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={sellModalVisible}
                onRequestClose={closeSellModal}
            >
                <View style={styles.modalContainer}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <View style={styles.modalContent}>
                            <Text style={styles.modalTitle}>Sell NFT</Text>

                            <Text style={styles.label}>Select NFT:</Text>
                            <Picker
                                selectedValue={selectedSellNft}
                                onValueChange={(itemValue) => setSelectedSellNft(nftData.find(nft => nft.nftId === itemValue))}
                            >
                                {nftData.map((nft) => (
                                    <Picker.Item label={nft.nftCollection} value={nft.nftId} key={nft.nftId} />
                                ))}
                            </Picker>
                            <Text style={styles.label}>Indicate the sale amount:</Text>
                            <TextInput
                                placeholder="Price"
                                value={sellPrice}
                                onChangeText={setSellPrice}
                                keyboardType="numeric"
                                style={styles.input}
                            />

                            <Text style={styles.label}>Select sales currency:</Text>
                            <Picker
                                selectedValue={currency || ''}
                                onValueChange={(itemValue) => setCurrency(itemValue)}
                            >
                                {currencies.map((currency) => (
                                    <Picker.Item label={currency} value={currency} key={currency} />
                                ))}
                            </Picker>

                            <Text style={styles.label}>Provide details to receive payment:</Text>
                            <TextInput
                                placeholder="Payment address"
                                value={paymentAddress}
                                onChangeText={setPaymentAddress}
                                style={styles.input}
                            />

                            <Text style={styles.label}>The amount of the pledge and the outpost is equal to the amount of the sale in cashback coin</Text>
                            <View style={styles.modalButtons}>
                                <Button title="Close" onPress={closeSellModal} />
                                <Button title="Sell NFT" onPress={handleSellNft} />
                            </View>
                        </View>
                    )}
                    <Toast style={{ zIndex: 9999 }} />
                </View>
            </Modal>

            {/* Кнопка "+" для открытия модального окна продажи */}
            <TouchableOpacity style={styles.fab} onPress={openSellModal}>
                <Text style={styles.fabText}>+</Text>
            </TouchableOpacity>

            <Toast style={{ zIndex: 9999 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    nftList: {
        flex: 1,
    },
    nftItem: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 10,
        padding: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 2,
    },
    nftImage: {
        width: 100,
        height: 100,
        borderRadius: 8,
        marginRight: 10,
    },
    nftInfo: {
        flex: 1,
    },
    nftName: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    nftArtist: {
        fontSize: 16,
        color: '#666',
        marginBottom: 5,
    },
    nftPrice: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#4CAF50',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 8,
        width: '80%',
    },
    modalTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    modalImage: {
        width: '100%',
        height: 200,
        borderRadius: 8,
        marginBottom: 10,
    },
    modalInfo: {
        fontSize: 18,
        marginBottom: 5,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 20,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 8,
    },
    fab: {
        position: 'absolute',
        right: 20,
        bottom: 20,
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: '#007bff',
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 5,
    },
    fabText: {
        fontSize: 30,
        color: 'white',
        lineHeight: 30,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        marginVertical: 10,
    },
});

export default NftMarketplaceScreen;