import React, { useState, useEffect } from 'react';
import { Modal, View, Text, Button, Image, TextInput, Linking, ScrollView } from 'react-native';
import Toast from 'react-native-toast-message';
import Loader from './Loader.js';
import BazerService from '../api/bazer/BazerService.js';
import AiService from '../api/ai/AiService.js';

const CreateNftModal = ({ visible, seedPhrase, onClose, dataСreateImages }) => {
    const [linkImageObject, setLinkImageObject] = useState({ linkGPT: null, link: null });
    const [loading, setLoading] = useState(false);
    const [visibleTwo, setVisibleTwo] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [nftName, setNftName] = useState("");
    const [description, setDescription] = useState("");
    const [reserveAmount, setReserveAmount] = useState("");
    const [collectionName, setCollectionName] = useState("");
    const [hashNft, setHashNft] = useState("");

    const showToastError = (text) => Toast.show({
        type: 'error',
        text1: text,
    });

    const incrementValue = () => setQuantity(prevValue => (prevValue < 10 ? prevValue + 1 : prevValue));
    const decrementValue = () => setQuantity(prevValue => (prevValue > 1 ? prevValue - 1 : prevValue));

    const openLink = () => Linking.openURL(`https://explorer.decimalchain.com/transactions/${hashNft}`);

    useEffect(() => {
        const createImages = async () => {
            try {
                setLoading(true);
                if (!visible) return;
                const getImage = await AiService.createImages(dataСreateImages.prompt);
                if (getImage.status === "ok") {
                    setLinkImageObject(getImage.data)
                } else {
                    showToastError("Error create image!");
                }
            } catch (error) {
                console.error('Error create image: ', error);
            } finally {
                setLoading(false);
            }
        };

        createImages();
    }, [dataСreateImages]);

    const createNft = async () => {
        setLoading(true);
        const validate = validateNFT(nftName, collectionName, description, reserveAmount);
        if (typeof validate === "string") {
            setLoading(false);
            return showToastError(validate);
        } else {
            try {
                const data = await BazerService.CreateNft(seedPhrase, {
                    linkImage: linkImageObject.link,
                    headline: nftName,
                    denom: collectionName,
                    description,
                    quantity,
                    reserveAmount
                });
                if (data.status === "error") throw new Error(data.errors.translationError || "Error create nft!");
                setHashNft(data.data.txID)
                onClose();
                setVisibleTwo(true);
            } catch (error) {
                showToastError("Error create nft!");
            } finally {
                setLoading(false);
            }
        }
    };

    const onCloseAll = () => {
        onClose();
        setVisibleTwo(false);
    }

    return (
        <View>
            <Modal
                visible={visible}
                animationType="slide"
                transparent={true}
            >
                <View style={styles.modalContainer}>
                    <ScrollView contentContainerStyle={styles.scrollContainer}>
                        <View style={styles.modalContent}>
                            <Text style={styles.modalTitle}>Create Nft</Text>
                            <View style={styles.imageContainer}>
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <View>
                                        <Image
                                            source={{ uri: linkImageObject.linkGPT }}
                                            style={styles.image}
                                        />
                                        <TextInput
                                            style={styles.input}
                                            onChangeText={setCollectionName}
                                            value={collectionName}
                                            placeholder="Collection name"
                                        />
                                        <TextInput
                                            style={styles.input}
                                            onChangeText={setNftName}
                                            value={nftName}
                                            placeholder="NFT name"
                                        />
                                        <TextInput
                                            style={styles.input}
                                            onChangeText={setReserveAmount}
                                            value={reserveAmount}
                                            placeholder="Reserve amount"
                                            keyboardType="numeric"
                                        />
                                        <TextInput
                                            style={styles.input}
                                            onChangeText={setDescription}
                                            value={description}
                                            placeholder="Description"
                                        />
                                        <Text style={styles.textQuantity}>Quantity:</Text>
                                        <View style={styles.containerEditValue}>
                                            <Button title="-" onPress={decrementValue} />
                                            <TextInput
                                                style={styles.inputEditValue}
                                                value={quantity.toString()}
                                                keyboardType="numeric"
                                                onChangeText={(text) => {
                                                    const num = parseInt(text);
                                                    if (!isNaN(num) && num >= 1 && num <= 10) {
                                                        setQuantity(num);
                                                    }
                                                }}
                                            />
                                            <Button title="+" onPress={incrementValue} />
                                        </View>
                                        <Button title="Create" color="green" onPress={createNft} />
                                    </View>
                                )}
                            </View>
                            <Button title="Close" onPress={onCloseAll} />
                        </View>
                    </ScrollView>
                    <Toast style={{ zIndex: 9999 }} />
                </View>
            </Modal>
            <Modal
                visible={visibleTwo}
                animationType="slide"
                transparent={true}
            >
                <View style={styles.modalContainerTwo}>
                    <Toast />
                    <View style={styles.modalContentTwo}>
                        <Button style={{ marginBottom: 10 }} title="Open Explorer" onPress={openLink} />
                        <Button style={{ marginTop: 10 }} title="Close" onPress={onCloseAll} />
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const validateNFT = (nftName, collectionName, description, reserveAmount) => {
    if (nftName.length < 3) return "NFT name must be at least 3 characters long";
    if (collectionName.length < 3) return "Collection name must be at least 3 characters long";
    if (description.length < 3) return "Description must be at least 3 characters long";
    if (reserveAmount <= 0) return "Reserve amount must be greater than zero";
    return true;
}

const styles = {
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    imageContainer: {
        backgroundColor: 'gray',
        alignItems: 'center',
        marginVertical: 20,
        padding: 20,
        borderRadius: 10,
        alignSelf: 'center',
    },
    image: {
        width: "100%",
        height: 200,
        resizeMode: 'cover',
    },
    input: {
        height: 50,
        marginVertical: 10,
        borderWidth: 1,
        padding: 10,
        width: '100%',
        borderRadius: 5,
        borderColor: '#ddd',
        fontSize: 16,
    },
    containerEditValue: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputEditValue: {
        height: 50,
        width: 50,
        margin: 10,
        borderColor: '#ddd',
        borderWidth: 1,
        textAlign: 'center',
    },
    textQuantity: {
        fontSize: 16,
        marginBottom: 5,
    },
    modalContainerTwo: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContentTwo: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        width: '80%',
    },
    scrollContainer: {
        flexGrow: 1,
        justifyContent: 'center',
        paddingHorizontal: 20,
    },
};

export default CreateNftModal;